<!-- 消息管理 -->
<template>
  <div id="message">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>消息管理</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="接收人">
              <el-input
                placeholder="请输入审批人"
                v-model.trim="requestParam.recipientUser"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="消息状态">
              <el-select
                :popper-append-to-body="false"
                v-model="requestParam.messageFlag"
                placeholder="请选择消息状态"
                clearable
              >
                <el-option label="已读" value="1"></el-option>
                <el-option label="未读" value="2"></el-option>
              </el-select>
            </el-form-item>

            <!-- <el-form-item label="消息范围">
              <el-select
                :popper-append-to-body="false"
                v-model="requestParam.messageScope"
                placeholder="请选择消息范围"
                clearable
              >
                <el-option label="PC" value="1"></el-option>
                <el-option label="移动" value="2"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="模块">
              <el-select
                :popper-append-to-body="false"
                v-model="requestParam.module"
                placeholder="请选择模块"
                clearable
              >
                <el-option label="运维管理" value="1"></el-option>
                <el-option label="计划管理" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker
                placeholder="请选择开始时间"
                type="date"
                value-format="yyyy-MM-dd"
                clearable
                v-model="requestParam.createdAtStart"
                :append-to-body="false"
                @change="checkDate"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker
                placeholder="请选择结束时间"
                type="date"
                value-format="yyyy-MM-dd"
                v-model="requestParam.createdAtEnd"
                :picker-options="pickerOptions"
                :append-to-body="false"
                @change="checkDate"
              ></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery"
              >查询</el-button
            >
          </div>
        </div>

        <el-table
          class="table"
          :data="tableData"
          v-loading="loading"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)"
        >
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="table-text">
                  {{ loading ? "" : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="moduleName"
            label="模块"
            width="100"
          >
          </el-table-column>
          <el-table-column align="center" prop="sendCondition" label="发送条件">
          </el-table-column>
          <el-table-column align="center" prop="title" label="标题">
          </el-table-column>
          <el-table-column
            align="center"
            prop="sendTxt"
            label="发送文本"
            width="175"
          >
          </el-table-column>
          <!-- <el-table-column align="center" prop="latitude" label="链接">
          </el-table-column> -->
          <el-table-column align="center" prop="recipientUser" label="接收人">
          </el-table-column>
          <!-- <el-table-column align="center" prop="question" label="问题">
          </el-table-column> -->
          <!-- <el-table-column align="center" prop="messageScope" label="消息范围">
            <template slot-scope="scope">
              <div v-show="scope.row.messageScope === 1">PC</div>
              <div v-show="scope.row.messageScope === 2">移动</div>
            </template>
          </el-table-column> -->
          <el-table-column
            align="center"
            prop="remark"
            label="备注"
            width="175"
          >
          </el-table-column>
          <el-table-column align="center" prop="messageFlag" label="消息状态">
            <template slot-scope="scope">
              <span>{{ scope.row.messageFlag === 1 ? "已读" : "未读" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createTime"
            label="创建时间"
            width="150"
          >
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="updateDialog(scope.row)"
                  >详情</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination
            :current-page="requestParam.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot"
            prev-text="上一页"
            next-text="下一页"
            :total="total"
            background
            @size-change="changeSize"
            @current-change="changeCurrent"
          >
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input
              v-model="inputValue"
              size="mini"
              class="pagination-input"
              @input="checkPageNum"
              @change="inputValueChange"
            ></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      width="751px"
      @close="dialogClose"
    >
      <div class="dialog-content">
        <el-form class="dialog-form" :inline="true" :model="detailsData">
          <el-form-item label="模块">
            <el-input v-model="detailsData.moduleName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="发送条件">
            <el-input
              v-model="detailsData.sendCondition"
              :readonly="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="detailsData.title" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input
              v-model="detailsData.createName"
              :readonly="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="发送文本">
            <el-input
              v-model="detailsData.sendTxt"
              :readonly="true"
              type="textarea"
              :rows="3"
            ></el-input>
          </el-form-item>
          <el-form-item label="接收人">
            <el-input
              v-model="detailsData.recipientUser"
              :readonly="true"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="消息状态">
            <el-input
              v-model="detailsData.scopeName"
              :readonly="true"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="消息状态">
            <el-input
              v-model="detailsData.readUnread"
              :readonly="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="detailsData.remark"
              :rows="3"
              :readonly="true"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
  </div>
</template>
<script>
import { messageApi } from "@/api/message.js";
import eventBus from "../../utils/bus";
export default {
  name: "",

  data() {
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        recipientUser: "",
        messageFlag: "",
        messageScope: "",
        module: "",
        createdAtEnd: null,
        createdAtStart: null,
      },
      total: 0,
      inputValue: "",
      id: "",
      loading: false,
      dialogVisible: false,
      detailsData: {},
    };
  },

  created() {
    this.$store.commit("increment", "日常办公");
    this.$store.commit("selectChild", "消息管理");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryMessage(); //获取消息
    let selectedMessage = this.$route.params;
    if (Object.keys(selectedMessage).length !== 0) {
      this.detailsData = selectedMessage;
      this.id=selectedMessage.id;
      this.dialogVisible = true;
    }
    eventBus.$on("selectedMessage", (value) => {
      if (Object.keys(value).length !== 0) {
        this.detailsData = value;
        this.id = value.id;
        // console.log("选中的消息", this.detailsData);
        this.dialogVisible = true;
      }
    });
  },
  methods: {
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryMessage();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryMessage();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryMessage();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryMessage();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryMessage();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryMessage();
    },
    checkDate() {
      if (this.requestParam.createdAtStart && this.requestParam.createdAtEnd) {
        const startTime = new Date(this.requestParam.createdAtStart).getTime();
        const endTime = new Date(this.requestParam.createdAtEnd).getTime();
        if (startTime > endTime) {
          this.requestParam.createdAtEnd = null;
        }
      }
    },
    updateDialog(data) {
      this.id = data.id;
      this.detailsData = data;
      // this.detailsData.messageFlag =
      //   this.detailsData.messageFlag === 1 ? "已读" : "未读";
      // this.detailsData.messageScope =
      //   this.detailsData.messageScope === 1 ? "PC" : "移动";
      this.dialogVisible = true;
    },
    dialogClose() {
      if (this.detailsData.messageFlag === 2) {
        this.updateMessageStatus();
      }
    },
    queryMessage() {
      this.loading = true;
      messageApi.queryMessage(this.requestParam).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    updateMessageStatus() {
      messageApi.updateMessageStatus(this.id, 1).then((res) => {
        // this.$message({
        //   message: "修改成功",
        //   type: "success",
        //   duration: 3000,
        //   customClass: "messageText",
        // });
        this.$store.dispatch("queryMessage");
        this.queryMessage();
      });
    },
  },
  computed: {
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.createdAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#message {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}
/* .el-link.el-link--default{
color: #aed6ff;
}
.el-link.is-underline:hover:after{
  border-bottom: 1px solid #aed6ff;
} */
/* 执行结果 */
.dialog-content {
  margin: 20px 41px 0 41px;
}
.dialog-form :deep().el-form-item__label {
  width: 67px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.dialog-form :deep().el-form-item__label::after {
  content: ":";
  margin: 0 3px;
}
.dialog-form :deep() .el-form-item {
  margin: 0 0 16px 0;
}
.dialog-form :deep() .el-input {
  width: 257px;
}
.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}
.dialog-form :deep() .el-textarea textarea {
  width: 592px;
  background-color: #061b45;
  border: 1px solid #09295b;
  /* border: 0; */
  font-size: 12px;
  color: #aed6ff;
}
.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}
.dialog-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}
</style>
